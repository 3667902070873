@import '../mixins/progress';
@import 'colors';

body {
	background-color: $backgroundColor;
	color: $textColor;
}

.highcharts-background {
	fill: $backgroundColor !important;
}

.highcharts-tooltip-box {
	fill: $backgroundColor !important;
}

.mainform fieldset div.form-row select {
	background-color: $backgroundColor;
}

a:not(.btn),
.link {
	cursor: pointer;
	color: $textColor;
	&:hover {
		color: $highlightColor;
	}
}
.icon-delete,
.icon-remove {
	cursor: pointer;
	color: #d12626;
	&:hover {
		color: #fa0505;
	}
}
.icon-add {
	cursor: pointer;
	color: #04840e !important;
	&:hover {
		color: #09da24 !important;
	}
}
.icon-copy {
	&:hover {
		color: $highlightColor;
	}
}
.icon-edit {
	cursor: pointer;
	color: darkorange;
	&:hover {
		color: orange;
	}
}

.btn-success,
.panel-success {
	color: $successTextColor;
	background-color: $successBackgroundColor;
	border-color: $successLineColor;
}

.btn-info,
.panel-info {
	color: $unknownTextColor;
	background-color: $unknownBackgroundColor;
	border-color: $unknownLineColor;
}

.btn-warning,
.panel-warning {
	color: $warningTextColor;
	background-color: $warningBackgroundColor;
	border-color: $warningLineColor;
}

.btn-error,
.panel-error {
	color: $errorTextColor;
	background-color: $errorBackgroundColor;
	border-color: $errorLineColor;
}

.card-status-error {
	background-color: $errorBackgroundColor;
}

.card-status-busy {
	background-color: $successBackgroundColor;
}

.card-status-wait {
	background-color: $warningBackgroundColor;
}

.card-status-done {
	background-color: $successBackgroundColor;
}

main {
	&#main-login {
		.information {
			a {
				color: $highlightColor !important;
				&:hover {
					color: $textColor !important;
				}
			}
		}

		form#login,
		form#lostPassword {
			label[for='autologin'] {
				color: $headerDisabledTextColor;
			}

			input {
				&[type='email'],
				&[type='password'],
				&[type='text'] {
					color: $headerTextColor;
					border-bottom: 1px solid $highlightColor;

					&:invalid:not(:placeholder-shown) {
						border-bottom-color: $errorLineColor;
					}
				}
				&[type='submit'] {
					background: linear-gradient(to bottom, #783cb6 5%, #46216b 100%);
					color: #fff;
					&:hover {
						background: darken(#783cb6, 10%);
					}
				}
			}

			.cards-style-card {
				.card:hover {
					cursor: default;
					background-color: $cardBackground;
				}
			}
		}
	}
}

.rangeSlider__fill {
	background: $highlightColor;
}

::placeholder {
	color: $labelColor;
	opacity: 0.5;
}
::-ms-placeholder {
	color: $labelColor;
	opacity: 0.5;
}
::-webkit-input-placeholder {
	color: $labelColor;
	opacity: 0.5;
}
::-moz-placeholder {
	color: $labelColor;
	opacity: 0.5;
}
[contenteditable='true']:empty:not(:focus):before {
	color: $labelColor;
	opacity: 0.5;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 100px $backgroundColor inset;
	-webkit-text-fill-color: $textColor;
}

#help {
	h2 {
		border-bottom: 1px solid $highlightColor;
	}

	div.highlight {
		border-color: $highlightColor;
	}
}

div.help {
	border-color: $helpBorderColor;
	color: $helpTextColor;
	background-color: $helpBackground;
}

.mainform {
	hr {
		border-top-color: $highlightColor;

		&.disabled {
			border-top-color: $labelColor;
		}
	}

	fieldset {
		legend,
		h2,
		h3 {
			border-bottom: 1px solid $highlightColor;
		}

		.jodit-container,
		#portfolioLayout {
			i.fa-times-circle {
				color: $errorBackgroundColor;
			}
			i.fa-check-circle {
				color: $successBackgroundColor;
			}
		}

		label {
			color: $labelColor;
			&.tag {
				color: #fff;
			}
		}
		.question {
			.question-answer {
				&.question-incorrect {
					i.fa-times {
						color: $errorBackgroundColor;
					}
					&.question-answered {
						color: $errorBackgroundColor;
					}
				}
				&.question-correct {
					i.fa-check {
						color: $successBackgroundColor;
					}
					&.question-answered {
						color: $successBackgroundColor;
					}
				}
			}
		}

		div.form-row {
			input,
			select,
			textarea,
			div.formfield,
			div.portfolio-star-container {
				color: $textColor;
				border-color: $labelColor;

				&:focus {
					border-color: $highlightColor;

					+ label {
						color: $highlightColor;
					}
				}
			}

			.form-row-stars:not(.readonly) {
				i {
					cursor: pointer;
					color: $textColor;
				}

				&:hover i {
					color: $highlightColor;
				}

				i:hover ~ i {
					color: $textColor;
				}
			}

			&.readonly {
				.tag {
					background-color: $disabledBackgroundColor;
				}
			}

			.form-hr {
				border-color: $labelColor;
			}

			.form-multiselect {
				.tag:hover {
					background: $errorBackgroundColor;
				}
			}
		}

		.form-switch {
			.form-slider {
				&:before {
					color: gray;
				}
			}

			input {
				&:checked:not(:disabled) + .form-slider {
					background-color: $highlightColor;
					&:before {
						color: $highlightColor;
					}
				}

				&:disabled + .form-slider {
					background-color: lightGray;
					&:before {
						background-color: darkGray;
						color: lightGray;
					}
				}
			}
		}
	}

	.autocomplete-items {
		border-color: $labelColor;

		div {
			background-color: $rowOdd;
			border-bottom-color: $labelColor;

			&:hover {
				background-color: $highlightColor;
			}
		}
	}

	.autocomplete-active {
		background-color: $highlightColor !important;
		color: $textColor;
	}
}

#calculation {
	border-color: $labelColor;
}

.tag {
	&:hover {
		opacity: 0.6;
	}
	background-color: $highlightColor;
	color: #fff;

	&.tag-success {
		background-color: $successBackgroundColor;
		color: $successTextColor;
	}
	&.tag-warning {
		background-color: $warningBackgroundColor;
		color: $warningTextColor;
	}
	&.tag-error {
		background-color: $errorBackgroundColor;
		color: $errorTextColor;
	}
}

input[type='submit'],
button:not(.vjs-button, .swal2-styled, .jodit-toolbar-button__button, .emoji-picker__emoji, .jodit-ui-button),
.btn {
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, lighten($headerColor, 20)), color-stop(1, $headerColor));
	background: -moz-linear-gradient(top, lighten($headerColor, 20) 5%, $headerColor 100%);
	background: -webkit-linear-gradient(top, lighten($headerColor, 20) 5%, $headerColor 100%);
	background: -o-linear-gradient(top, lighten($headerColor, 20) 5%, $headerColor 100%);
	background: -ms-linear-gradient(top, lighten($headerColor, 20) 5%, $headerColor 100%);
	background: linear-gradient(to bottom, lighten($headerColor, 20) 5%, $headerColor 100%);
	background-color: $lineColor;
	border: 1px solid darken($headerColor, 20);
	color: $headerTextColor;

	-webkit-tap-highlight-color: rgba($highlightColor, 0.6);

	&:hover {
		border: 1px solid darken($headerColor, 20);
		background: -webkit-gradient(
			linear,
			left top,
			left bottom,
			color-stop(0.05, darken($headerColor, 10)),
			color-stop(1, $headerColor)
		);
		background: -moz-linear-gradient(top, darken($headerColor, 10) 5%, $headerColor 100%);
		background: -webkit-linear-gradient(top, darken($headerColor, 10) 5%, $headerColor 100%);
		background: -o-linear-gradient(top, darken($headerColor, 10) 5%, $headerColor 100%);
		background: -ms-linear-gradient(top, darken($headerColor, 10) 5%, $headerColor 100%);
		background: linear-gradient(to bottom, darken($headerColor, 10) 5%, $headerColor 100%);
		background-color: #e9e9e9;
	}
}

@media (hover: none) {
	input[type='submit'],
	button:not(.vjs-button):not(.swal2-styled),
	.btn {
		&:hover {
			background: -webkit-gradient(
				linear,
				left top,
				left bottom,
				color-stop(0.05, lighten($headerColor, 20)),
				color-stop(1, $headerColor)
			);
			background: -moz-linear-gradient(top, lighten($headerColor, 20) 5%, $headerColor 100%);
			background: -webkit-linear-gradient(top, lighten($headerColor, 20) 5%, $headerColor 100%);
			background: -o-linear-gradient(top, lighten($headerColor, 20) 5%, $headerColor 100%);
			background: -ms-linear-gradient(top, lighten($headerColor, 20) 5%, $headerColor 100%);
			background: linear-gradient(to bottom, lighten($headerColor, 20) 5%, $headerColor 100%);
			background-color: $lineColor;
			border: 1px solid darken($headerColor, 20);
			color: $headerTextColor;
		}
	}
}

header {
	background-color: $headerColor;
	color: $headerTextColor;

	.toggle:hover,
	#backButton:hover,
	#mainaction:hover,
	a:hover,
	.dd-button:hover {
		color: $highlightColor;
	}

	#search {
		input {
			color: $textColor;
			background-color: transparent;
			border: none;
			border-bottom: 1px solid $highlightColor;
		}
	}
}

.sidebar {
	background-color: $sideMenuColor;
	color: $sideMenuColorTextColor;

	ul.sidemenu {
		li {
			&.active div {
				color: $highlightColor;
			}

			&.sidemenu-item > div,
			&.sidemenu-sub > label > div {
				border-bottom: 1px solid $cardBackground;

				&:hover {
					color: $highlightColor;
				}

				i.quick-add {
					color: $labelColor;
				}
			}
		}
	}

	.userinformation:hover {
		color: $sideMenuColorTextColor;
	}
}

.sticky-header {
	background: $backgroundColor;
}

.pathbar {
	li {
		color: $sideMenuColorTextColor;

		& + li:before {
			color: $cardBackground;
		}

		&:last-child {
			color: $cardBackground;
		}

		&:hover {
			color: $highlightColor;
		}
	}
}

.mainform {
	fieldset {
		label.dropdown {
			color: $textColor;
		}
	}
}

.dropdown {
	.dd-menu {
		border: 1px solid $dropdownBorderColor;
		box-shadow: $shadow;
		background-color: $dropdownBackgroundColor;
		color: $dropdownTextColor;

		li {
			&.active {
				background-color: $labelColor;
			}
			&.disabled {
				color: $labelColor;
			}

			div:hover {
				background-color: $highlightColor;
				color: #fff;
				border-radius: 4px;
			}

			label:hover {
				background-color: $highlightColor;
				color: #fff;
				border-radius: 4px;
			}

			&.divider {
				border-bottom: 1px solid $dropdownBorderColor;
			}

			&.dd-seperator {
				color: $textColorDisabled;
				border-bottom-color: $dropdownBorderColor;
				div:hover {
					background-color: inherit;
				}
			}
		}

		hr {
			border-color: $dropdownBorderColor;

			&.disabled {
				border-color: $labelColor;
			}
		}
	}
}

.tree ul {
	.tree-label {
		color: $textColor;
	}

	.tree-label[data-action]:hover {
		cursor: pointer;
		color: $highlightColor;
	}
}

#mainform {
	div {
		input[readonly] {
			color: $textColor;
		}
	}
}

.card-icon,
.card-icon-img {
	background-color: darken($cardBackground, 10%);

	i.fa-inverse {
		color: $cardBackground;
	}
}

.card-status {
	color: #fff;
	&.card-status-online {
		background-color: $successBackgroundColor;
	}
	&.card-status-offline {
		background-color: $offlineBackgroundColor;
	}
	&.card-status-unknown {
		background-color: $unknownBackgroundColor;
	}
	&.card-status-disabled {
		background-color: $disabledBackgroundColor;
	}
	&.card-status-warning {
		background-color: $warningBackgroundColor;
	}
}

.background-red {
	background-color: $redColor;
}
.background-green {
	background-color: $greenColor;
}
.background-yellow {
	background-color: $yellowColor;
}
.background-orange {
	background-color: $orangeColor;
}
.background-grey {
	background-color: $greyColor;
}

.cards {
	.card {
		background: $cardBackground;
	}

	&.cards-style-card {
		.card:hover:not(.hidden) {
			background-color: $cardBackgroundHover;
		}
	}

	&.cards-style-thumb {
		.card:hover:not(.hidden) {
			opacity: 0.8;
		}

		.card-thumb {
			.card-icon-img,
			.card-icon {
				color: #fff;
				border: 2px solid darken($cardBackground, 10%);
				& > {
					border: none;
				}
			}

			.card-status-icon {
				color: #fff;
			}
		}

		.card-progress {
			.card-icon-img,
			.card-icon {
				border: none;
			}
		}
	}

	&.cards-style-list {
		.card:hover:not(.hidden) {
			background-color: $cardBackground;
		}

		> li:nth-child(odd) {
			background: $rowOdd;
		}

		> li:nth-child(even) {
			background: $rowEven;
		}

		.card-status-icon {
			color: #fff;
		}
	}
}

.progress {
	background-color: $disabledBackgroundColor;

	.progress-inner {
		background-color: $successBackgroundColor;

		&.waiting {
			background-color: $warningBackgroundColor;
		}

		&.error {
			background-color: $errorBackgroundColor;
		}
	}
}
.user-selector ul.user-selector-selected {
	i.fa-lock,
	i.fa-lock-open {
		color: $highlightColor;
	}
}
.user-selector,
.inline-files,
.element-selector,
.comment-right {
	ul {
		li {
			.user-selector-icon,
			.inline-file-icon,
			.element-selector-icon,
			.comment-file-icon {
				background-color: darken($cardBackground, 10%);
				color: #fff;
			}
		}
	}

	.user-selector-user,
	.user-selector-group,
	.element-selector-file {
		> label {
			color: $textColor;
		}
	}

	i.fa-times-circle {
		color: $errorBackgroundColor;
	}

	i.fa-check-circle {
		color: $successBackgroundColor;
	}
}

.card-progress {
	.card-top {
		background-color: darken($cardBackground, 10%);

		&[data-progress='0'] {
			background-image: none;
		}
		@for $i from 1 through 100 {
			&[data-progress='#{$i}'] {
				background-image: circular-progress(100, $i, $successBackgroundColor, darken($cardBackground, 10%));
			}
		}
	}

	&.card-progress-colors {
		.card-top {
			@for $i from 1 through 80 {
				&[data-progress='#{$i}'] {
					background-image: circular-progress(100, $i, $successBackgroundColor, darken($cardBackground, 10%));
				}
			}

			@for $i from 81 through 90 {
				&[data-progress='#{$i}'] {
					background-image: circular-progress(100, $i, $warningBackgroundColor, darken($cardBackground, 10%));
				}
			}

			@for $i from 91 through 99 {
				&[data-progress='#{$i}'] {
					background-image: circular-progress(100, $i, $errorBackgroundColor, darken($cardBackground, 10%));
				}
			}

			&[data-progress='100'] {
				background-image: circular-progress(100, 100, #ff0000, darken($cardBackground, 10%));
			}
		}
	}
}

.usage-progress {
	&.usage-progress-colors {
		.usage-top {
			@for $i from 0 through 50 {
				&[data-progress='#{$i}'] {
					background-image: circular-progress(100, $i, $errorBackgroundColor, darken($cardBackground, 10%));
				}
			}

			@for $i from 51 through 70 {
				&[data-progress='#{$i}'] {
					background-image: circular-progress(100, $i, $warningBackgroundColor, darken($cardBackground, 10%));
				}
			}

			@for $i from 70 through 99 {
				&[data-progress='#{$i}'] {
					background-image: circular-progress(100, $i, $successBackgroundColor, darken($cardBackground, 10%));
				}
			}

			&[data-progress='100'] {
				background-image: circular-progress(100, 100, $successBackgroundColor, darken($cardBackground, 10%));
			}
		}
	}
}

#drop-zone {
	&.drop-zone {
		border: 2px solid $cardBackground;
	}

	&.drop-zone-hover {
		border: 2px solid $successBackgroundColor;
		i {
			color: $successBackgroundColor;
		}
	}
}

mark {
	background: $highlightColor;
	color: #fff;
}

.noUi-target {
	.noUi-connect {
		background: $highlightColor;
	}
}

ul.tags-container {
	li {
		background: $highlightColor;
		color: #fff;

		&:hover {
			background: $errorBackgroundColor;
		}
	}
}

#highlightscheme-editor-window {
	.highlightscheme-editor-inspector {
		.highlightscheme-inspector-triggers {
			li:nth-child(even) {
				background: $rowOdd;
			}

			li:nth-child(odd) {
				background: $rowEven;
			}

			li.selected {
				background: $highlightColor;
			}
		}
	}

	#highlightscheme-inspector-links {
		.highlightscheme-inspector-link {
			&:nth-child(even) {
				background: $rowOdd;
			}

			&:nth-child(odd) {
				background: $rowEven;
			}
		}
	}
}

.playlist-item {
	&:nth-child(odd) {
		background: $rowOdd;
	}
	&:nth-child(even) {
		background: $rowEven;
	}
}

#analyser {
	#analyser-resize-handler-vertical {
		border-color: $headerColor;
	}

	#analyser-resize-handler-horizontal {
		border-color: $headerColor;
	}

	.analyser-top {
		.analyser-toolview {
			background-color: $analyserWindowBackground;
		}
	}

	.analyser-bottom {
		.analyser-toolbar {
			background-color: $analyserWindowBackground;
			border: 1px solid $analyserStrokeColor;

			.dd-menu > li > div:hover {
				color: #fff !important;
			}

			div:hover,
			label > i:hover {
				color: $highlightColor;
			}
		}

		.analyser-timeline {
			background-color: $analyserWindowBackground;
		}

		.analyser-bottom-toolbar {
			border: 1px solid $analyserStrokeColor;
			background-color: $analyserWindowBackground;
		}
	}
}

#analyser-toolview-playlist-buttons {
	div > i:hover,
	label > i:hover {
		color: $highlightColor;
	}
}

table {
	thead {
		tr {
			border-bottom: 1px solid $highlightColor;
		}
	}

	&.alternating-rows {
		tbody {
			tr {
				&:nth-child(odd) {
					background: $rowOdd;
				}
				&:nth-child(even) {
					background: $rowEven;
				}
			}
		}
	}

	&.hover-rows {
		tbody {
			tr:hover {
				td {
					background: $highlightColor;
					color: #fff;
				}
			}
		}
	}

	&#tableChartContainer {
		tbody {
			tr {
				td {
					i:hover {
						color: $highlightColor;
					}
				}
			}
		}
	}
}

.questionnaire-reports {
	.questionnaire-report {
		table {
			thead {
				tr {
					th {
						&:nth-child(even) div {
							background-color: $rowOdd;
						}
						&:nth-child(odd) div {
							background-color: $rowEven;
						}
					}
				}
			}

			tbody {
				tr {
					td,
					th {
						&:nth-child(even) {
							background-color: $rowOdd;
						}
						&:nth-child(odd) {
							background-color: $rowEven;
						}
					}
					td:not(.no-hover):not(:empty):hover {
						cursor: pointer;
						background-color: $highlightColor !important;
						color: $textColor !important;
					}
				}
			}

			&.table-horizontal {
				tbody {
					tr {
						&:last-child {
							border-top: 1px solid $highlightColor;
						}
					}
				}
			}
			&.table-vertical {
				tbody {
					tr {
						td {
							&:last-child {
								border-left: 1px solid $highlightColor;
							}
						}
					}
				}
			}
		}
	}
	i {
		&.fa-check-circle {
			color: $successBackgroundColor;
		}
		&.fa-times-circle {
			color: $errorBackgroundColor;
		}
		&.fa-question-circle {
			color: $warningBackgroundColor;
		}
	}
}
.questionnaire-report-answer {
	.report {
		color: $highlightColor;
	}
	.question {
		color: $highlightColor;
		border-bottom: 1px solid $highlightColor;
	}
}

.questionnaire-progress {
	background-color: $baseColor;
	.questionnaire-progress-inner {
		background-color: $highlightColor;
		color: #fff;
	}
}

#questionnaireAnswer {
	.buttons {
		.btn {
			&:not(.question-active) {
				.fa {
					color: $rowOdd;
				}
			}
		}
	}
}

.dropdown {
	&.active {
		color: $highlightColor;
	}
}

#calendar {
	.header {
		background: $backgroundColor;
	}

	.calendar {
		.week {
			.week-container {
				background: $rowOdd;
				color: darken($textColor, 30%);

				.week-number,
				.week-fullday {
					background: $rowOdd;
					color: darken($textColor, 30%);
					border-right: 1px solid #444;
				}
			}
			.day {
				background: $rowEven;

				.day-header,
				.day-fullday {
					background: $rowEven;
				}

				.day-hour,
				.day-fullday {
					border-bottom-color: $rowOdd;
				}

				&.day-weekend {
					background: $rowOdd;
					.day-hour,
					.day-fullday {
						border-bottom-color: $rowEven;
					}

					.day-header,
					.day-fullday {
						background: $rowOdd;
					}
				}

				&.dropday {
					position: relative;
					background: $highlightColor;
					&:before {
						display: flex;
						align-items: center;
						position: absolute;
						font-family: 'Font Awesome 5 Free';
						content: '\f0ea';
						font-weight: bold;
						opacity: 0.5;
						font-size: 60px;
						font-weight: 900;
						height: 100%;
						width: 100%;
						left: -30px;
						margin-left: 50%;
						margin-right: 50%;
						vertical-align: middle;
					}
				}

				&.dragging {
					.day-header {
						background: $highlightColor;

						&:before {
							font-family: 'Font Awesome 5 Free';
							content: '\f0c5';
							font-weight: bold;
							display: inline-block;
							font-size: 0.8em;
							padding-left: 3px;
							vertical-align: middle;
							font-weight: 900;
						}
					}
				}
			}
		}
	}

	&.calendar-week,
	&.calendar-month {
		.calendar .week .day {
			&.today {
				background-color: #999;

				.day-appointment {
					background-color: #999 !important;
				}

				.day-header {
					background-color: #999;
				}
			}

			&.selected {
				color: #fff;
				background-color: $highlightColor;

				.day-appointment {
					color: #fff;
					background-color: $highlightColor !important;
				}

				.day-header {
					color: #fff;
					background-color: $highlightColor;
				}
			}
		}
	}

	&.calendar-week-full,
	&.calendar-day {
		.calendar .week .day {
			&.today .day-header {
				background-color: #999;
			}

			&.selected .day-header {
				color: #fff;
				background-color: $highlightColor;
			}
		}
	}

	.categories {
		.tag.disabled {
			text-decoration: line-through;
			background-color: $disabledBackgroundColor !important;
		}
	}
}

.flashing {
	animation: flashing 1s ease-out;
	animation-iteration-count: 1;

	-moz-animation: flashing 1s ease-out;
	-moz-animation-iteration-count: 1;

	-webkit-animation: flashing 1s ease-out;
	-webkit-animation-iteration-count: 1;

	-ms-animation: flashing 1s ease-out;
	-ms-animation-iteration-count: 1;
}

#loader {
	background-color: rgba($backgroundColor, 0.5);
}

@keyframes flashing {
	0% {
		background: transparent;
	}
	25% {
		background: $highlightColor;
	}
	50% {
		background: transparent;
	}
	75% {
		background: $highlightColor;
	}
	100% {
		background: transparent;
	}
}

@-webkit-keyframes flashing {
	0% {
		background: transparent;
	}
	25% {
		background: $highlightColor;
	}
	50% {
		background: transparent;
	}
	75% {
		background: $highlightColor;
	}
	100% {
		background: transparent;
	}
}

@-moz-keyframes flashing {
	0% {
		background: transparent;
	}
	25% {
		background: $highlightColor;
	}
	50% {
		background: transparent;
	}
	75% {
		background: $highlightColor;
	}
	100% {
		background: transparent;
	}
}

@-ms-keyframes flashing {
	0% {
		background: transparent;
	}
	25% {
		background: $highlightColor;
	}
	50% {
		background: transparent;
	}
	75% {
		background: $highlightColor;
	}
	100% {
		background: transparent;
	}
}

.comments {
	.jodit_theme_dark {
		.jodit-icon {
			color: $textColor;
		}
	}

	.comments-container {
		.comments-reverse-button:hover {
			color: $highlightColor;
		}
		.comments-shift-return {
			color: $labelColor;
		}
		.comments-all {
			.comments-more:hover {
				color: $highlightColor;
			}
			.comment-container,
			.comment-replies {
				.comment {
					.comment-body {
						table tr :is(td, th) {
							border-color: $labelColor;
						}
					}

					.comment-replyTo {
						.comment-reply {
							color: $labelColor;
							&:hover {
								color: $highlightColor;
							}
						}
					}

					background: $rowOdd;
					&.comment-self {
						background: $rowEven;
					}

					.comment-left .comment-user-img .comment-user-icon {
						background-color: $backgroundColor;
					}

					.comment-right {
						.comment-footer {
							.comment-remove:hover {
								color: #fa0505;
							}
							.comment-reply:hover {
								color: $highlightColor;
							}
						}
					}
				}
			}
		}
	}
}

.conversion-status {
	i {
		&.conversion-status-no {
			color: $warningBackgroundColor;
		}
		&.conversion-status-busy {
			color: $successBackgroundColor;
		}
		&.conversion-status-done {
			color: $successBackgroundColor;
		}
		&.conversion-status-wait {
			color: $unknownBackgroundColor;
		}
		&.conversion-status-error {
			color: $errorBackgroundColor;
		}
		&.conversion-status-unknown {
			color: $unknownBackgroundColor;
		}
	}
}

.pdf-preview {
	i {
		color: $warningBackgroundColor;
	}
}

.pdf {
	.fa-times-circle {
		color: $errorBackgroundColor;
	}
	.fa-check-circle {
		color: $successBackgroundColor;
	}
}

#schedule {
	.schedule-table {
		thead {
			background: $rowEven;
		}
		tbody {
			.schedule-date {
				background: $rowOdd;
			}
		}

		&.schedule-table-categories {
			tbody {
				tr {
					border: 1px solid $labelColor;
					td {
						border: 1px solid $labelColor;
						background: $rowEven;
						&.weekend,
						&.week {
							background: $rowOdd;
						}
					}
				}
			}
		}
	}
}

.color-gold {
	color: #ffd700;
}

.color-silver {
	color: #c0c0c0;
}

.color-bronze {
	color: #cd7f32;
}

.notification-center {
	.notification-header {
		color: $textColor;
	}

	.notification {
		background: darken($backgroundColor, 3);

		&[data-template]:hover {
			.notification-header {
				color: #fff;
				background: $highlightColor;
			}
		}

		.notification-header {
			background: darken($backgroundColor, 5);

			i:hover {
				color: $highlightHoverColor;
			}
		}

		.notification-content {
			.notification-user {
			}
		}
	}
}

.comment-count {
	color: #fff !important;
	background-color: $highlightColor;
}

[data-template]:hover {
	cursor: pointer;
	input {
		color: $highlightColor !important;
		cursor: pointer;
	}
}

#shortlistLists {
	tr.shortlistListGroup {
		color: $headerTextColor;
		background: $rowOdd;
	}

	tr.shortlistListPlayer {
		& > td {
			border-bottom-color: $rowOdd;
			background: $cardBackground;
			&.playerFirstName {
				> i {
					color: $rowOdd;
				}
			}
		}
	}

	.content {
		div.state {
			border-right: 1px solid $backgroundColor;
		}
	}
}

#home {
	&:before {
		background: $highlightColor;
	}

	.home-item {
		.home-icon {
			color: #fff;
			background: $highlightColor;
		}

		.home-content {
			background: darken($backgroundColor, 3);
			h2 mark {
				background: darken($highlightColor, 20);
			}

			.home-more:hover {
				color: $highlightColor;
			}

			.home-header {
				background: $highlightColor;

				i {
					&:hover {
						color: $highlightHoverColor;
					}
				}
			}

			&:before {
				border-left: 7px solid $highlightColor;
			}

			&.right {
				&:before {
					border-right: 7px solid $highlightColor;
				}
			}
		}
	}
}

#removeSelectionSquare {
	background: rgba($highlightColor, 0.2);
	border-color: $highlightColor;
}

ul.cards-style-thumb {
	li.removeable-selected {
		.card-top {
			background: red;

			.card-icon-img,
			.card-icon {
				opacity: 0.4;
			}
		}
	}
}

ul.cards-style-card,
ul.cards-style-list {
	li.removeable-selected {
		.card-icon-img,
		.card-icon {
			background: red;
			opacity: 0.4;
		}
	}
}

#portfolioEditor {
	.section {
		border-color: rgba($labelColor, 0.6);
		&:hover {
			border-left-color: $highlightColor;
		}
	}

	> div:not(.section):not(.add-container),
	.section > div,
	.section-divider > div:not(.add-container),
	div.add {
		&:not(.section-divider):not(.edit) {
			border-color: rgba($labelColor, 0.6);
			&:hover {
				border-left-color: $highlightColor;
			}
		}
	}

	div.add-container {
		div.add {
			.options > div {
				border-color: rgba($labelColor, 0.6);

				i:hover {
					color: $highlightColor;
				}
			}
		}
	}
	i:not(.fa-star) {
		color: $labelColor;
	}

	.add-container .options i {
		color: $textColor;
	}

	i:hover {
		color: $highlightColor;
	}

	i.fa-trash-alt:hover {
		color: red;
	}

	div.add-container .add-reveal > div > i {
		color: $highlightColor;
	}
}

#portfolioLayout {
	p {
		color: $labelColor;
	}

	.portfolio-layout-table {
		border-collapse: collapse;
		empty-cells: show;
		max-width: 100%;
		border: 0px;
		width: 100%;

		th {
			border: 1px solid $labelColor;
			width: 25%;
			padding: 5px;
			text-align: left;
			vertical-align: top;
			font-weight: bold;
		}

		td {
			border: 1px solid $labelColor;
			width: 75%;
			padding: 5px;
		}
	}
}

#multiRemove {
	border: 2px solid $errorLineColor;
	background-color: $errorTextColor;
	color: $errorBackgroundColor;

	&.dragover {
		border-color: $errorTextColor;
		background-color: $errorBackgroundColor;
		color: $errorTextColor;
	}
}

.shortlists-add-user {
	.shortlists-add-user-search {
	}
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
	background: $highlightColor;
	border-color: $highlightColor;
}

.swal2-styled {
	&.swal2-confirm {
		background-color: $highlightColor;
	}
}
.swal2-input,
.swal2-textarea {
	color: #000;
}

.swal2-select {
	font-size: 1em;
}
.swal2-container {
	.link {
		color: #666;
		&:hover {
			color: $highlightColor;
		}
	}
}

.adminpage {
	.convertor-servers .server,
	.download-servers .server,
	.storage-servers .server,
	.storage-mountpoints .mountpoint {
		border-color: $highlightColor;
		&.server-busy {
			border-color: $successBackgroundColor;
		}
		&.server-offline {
			border-color: $warningBackgroundColor;
		}
		&.server-disabled {
			border-color: $offlineBackgroundColor;
		}
	}
}

@media screen and (max-width: 768px) {
	#home {
		.home-item {
			.home-content {
				&:before,
				&.right:before {
					border-left: 0;
					border-right: 7px solid $highlightColor;
				}
			}
		}
	}
}

.tactical-field-editor {
	background-color: $tacticalFieldBackgroundColor;
	color: $tacticalFieldTextColor;
	border-color: $tacticalFieldBorderColor;
	// .tactical-field-player {
	// 	border-color: $tacticalFieldBorderColor
	// }
	// .tactical-field-settings {
	// 	border-color: $tacticalFieldBorderColor
	// }
	// img {
	// 	border-color: $tacticalFieldBorderColor
	// }
}
.tactical-field {
	.tactical-field-image,
	.tactical-field-settings {
		border-color: $tacticalFieldBorderColor;
	}
	.tactical-field-settings-name {
		color: $tacticalFieldTextColor;
	}
}

// $tacticalFieldTextColor:		#fff;
// $tacticalFieldBackgroundColor:	#676767;
// $tacticalFieldBorderColor:		#323232;
