$backgroundColor:			#1e1e1e;
$rowOdd:					#323232;
$rowEven:					#1a1a1a;
$lineColor:					#212121;
$baseColor:					#333;
$highlightColor:			lighten(#5f2f90, 10);
$highlightHoverColor:		$lineColor;
$textColor:					#fff;
$labelColor:				gray;
$textColorDisabled:			#888;

$headerColor:				#191919;
$headerTextColor:			#fff;
$headerDisabledTextColor:	#ddd;

$sideMenuColor:				$headerColor;
$sideMenuColorTextColor:	$headerTextColor;

$dropdownBorderColor:		$headerColor;
$dropdownBackgroundColor:	lighten($backgroundColor, 10);
$dropdownTextColor:			$headerTextColor;

$cardBackground:			gray;
$cardBackgroundHover:		lightgray;

$shadow:					0 0 6px 0 rgba(0,0,0,0.1);

$analyserWindowBackground:	#353535;
$analyserStrokeColor:		#000;

$helpBackground:			#222;
$helpTextColor:				#999;
$helpBorderColor:			#444;

@import "include/skin";

.notification-center {
	.notification {
		&[data-template]:hover {
			.notification-content {
				background: darken($highlightColor, 30);
			}
		}
	}
}
